import { Button } from "@progress/kendo-react-buttons";
import { FieldWrapper } from "@progress/kendo-react-form";
import { Checkbox, TextArea } from "@progress/kendo-react-inputs";
import { Label, Error as LabelError } from "@progress/kendo-react-labels";
import { Autocomplete, GoogleMap, MarkerF } from "@react-google-maps/api";
import dayjs from "dayjs";
import { uniqBy } from "es-toolkit";
import {
	type CSSProperties,
	type ReactNode,
	createContext,
	useCallback,
	useContext,
	useMemo,
	useRef,
	useState,
} from "react";
import { useDropzone } from "react-dropzone";
import {
	type Control,
	type FieldError,
	type FieldPath,
	type FieldValues,
	FormProvider,
	type UseFormReturn,
	useController,
	useFormContext,
} from "react-hook-form";
import type { MultiValue, SingleValue } from "react-select";
import AsyncSelect from "react-select/async";
import ReactSelectCreatable from "react-select/creatable";
import { useAsync } from "react-use";
import styled from "styled-components";
import type { AnyObjectSchema, ObjectSchema } from "yup";
import { type GenericFormInputSelectOption, useSchemaInfo } from "../helpers";

const genericClassName = "k-input k-input-md k-rounded-md k-input-solid";
const StyledInput = styled.input.attrs({ className: genericClassName })`
		min-height: 38px;
		align-items: center;
`;
const StyledCheckbox = styled(Checkbox)`
		min-height: 38px;
		align-items: center;
`;

// Get any first "message" property from the error object on a recursive way
const getErrorMessage = (error: FieldError | undefined): string | undefined => {
	if (error == null) return undefined;
	if (error.message != null) return error.message;
	for (const value of Object.values(error)) {
		const result = getErrorMessage(value as FieldError);
		if (result) return result;
	}
	return undefined;
};

type GenericFormInputWrapperProps = {
	label: ReactNode;
	required: boolean | undefined;
	error: FieldError | undefined;
	children: ReactNode;
	style?: CSSProperties;
};
const GenericFormInputWrapper = ({
	label,
	required,
	error,
	children,
	style,
}: GenericFormInputWrapperProps) => (
	<FieldWrapper style={style}>
		<Label>
			{label}
			{required && <span style={{ color: "red" }}>*</span>}
		</Label>
		{children}
		<LabelError style={{ minHeight: "1.1rem" }}>
			{getErrorMessage(error)}
		</LabelError>
	</FieldWrapper>
);
type GenericFormInputProps<T extends FieldValues> = {
	label?: ReactNode;
	required?: boolean;
	s?: ObjectSchema<T>;
	n: FieldPath<T>;
	c?: Control<T>;
};
export const IDate = <T extends FieldValues>({
	label,
	required,
	n,
	c,
	s,
}: GenericFormInputProps<T>) => {
	const form = useFormContext<T>();
	const schema = useContext(SchemaContext);
	const { field, fieldState } = useController({
		name: n,
		control: c ?? form?.control,
	});
	const { _label, _required } = useSchemaInfo(n, s ?? schema, label, required);
	const dateJs = useMemo(() => dayjs(`${field.value}`), [field.value]);
	const dateValue = useMemo(
		() => (dateJs.isValid() ? dateJs.format("YYYY-MM-DD") : ""),
		[dateJs],
	);
	return (
		<GenericFormInputWrapper
			label={_label}
			required={_required}
			error={fieldState.error}
		>
			<StyledInput type="date" {...field} value={dateValue} />
		</GenericFormInputWrapper>
	);
};
export const IDateTime = <T extends FieldValues>({
	label,
	required,
	n,
	c,
	s,
}: GenericFormInputProps<T>) => {
	const form = useFormContext<T>();
	const schema = useContext(SchemaContext);
	const { field, fieldState } = useController({
		name: n,
		control: c ?? form?.control,
	});
	const { _label, _required } = useSchemaInfo(n, s ?? schema, label, required);
	const dateJs = useMemo(() => dayjs(`${field.value}`), [field.value]);
	const dateValue = useMemo(
		() => (dateJs.isValid() ? dateJs.format("YYYY-MM-DDTHH:mm") : ""),
		[dateJs],
	);
	return (
		<GenericFormInputWrapper
			label={_label}
			required={_required}
			error={fieldState.error}
		>
			<StyledInput type="datetime-local" {...field} value={dateValue} />
		</GenericFormInputWrapper>
	);
};
export const INumber = <T extends FieldValues>({
	label,
	required,
	n,
	c,
	s,
	step,
}: GenericFormInputProps<T> & { step?: string | number | undefined }) => {
	const form = useFormContext<T>();
	const schema = useContext(SchemaContext);
	const { field, fieldState } = useController({
		name: n,
		control: c ?? form?.control,
	});
	const { _label, _required } = useSchemaInfo(n, s ?? schema, label, required);
	return (
		<GenericFormInputWrapper
			label={_label}
			required={_required}
			error={fieldState.error}
		>
			<StyledInput type="number" step={step} {...field} />
		</GenericFormInputWrapper>
	);
};
export const IText = <T extends FieldValues>({
	label,
	required,
	n,
	c,
	s,
}: GenericFormInputProps<T>) => {
	const form = useFormContext<T>();
	const schema = useContext(SchemaContext);
	const { field, fieldState } = useController({
		name: n,
		control: c ?? form?.control,
	});
	const { _label, _required } = useSchemaInfo(n, s ?? schema, label, required);
	return (
		<GenericFormInputWrapper
			label={_label}
			required={_required}
			error={fieldState.error}
		>
			<StyledInput {...field} />
		</GenericFormInputWrapper>
	);
};

export const IPassword = <T extends FieldValues>({
	label,
	required,
	n,
	c,
	s,
}: GenericFormInputProps<T>) => {
	const form = useFormContext<T>();
	const schema = useContext(SchemaContext);
	const { field, fieldState } = useController({
		name: n,
		control: c ?? form?.control,
	});
	const { _label, _required } = useSchemaInfo(n, s ?? schema, label, required);
	return (
		<GenericFormInputWrapper
			label={_label}
			required={_required}
			error={fieldState.error}
		>
			<StyledInput type="password" {...field} />
		</GenericFormInputWrapper>
	);
};

export const ITextArea = <T extends FieldValues>({
	label,
	required,
	n,
	c,
	s,
}: GenericFormInputProps<T>) => {
	const form = useFormContext<T>();
	const schema = useContext(SchemaContext);
	const { field, fieldState } = useController({
		name: n,
		control: c ?? form?.control,
	});
	const { _label, _required } = useSchemaInfo(n, s ?? schema, label, required);
	return (
		// That's ((20+38+4)px + 1.1rem)*2
		<GenericFormInputWrapper
			label={_label}
			required={_required}
			error={fieldState.error}
			style={{ minHeight: "calc(124px + 2.2rem)" }}
		>
			<TextArea rows={5} {...field} />
		</GenericFormInputWrapper>
	);
};

export const ICheckbox = <T extends FieldValues>({
	label,
	required,
	n,
	c,
	s,
}: GenericFormInputProps<T>) => {
	const form = useFormContext<T>();
	const schema = useContext(SchemaContext);
	const { field, fieldState } = useController({
		name: n,
		control: c ?? form?.control,
	});
	const { _label, _required } = useSchemaInfo(n, s ?? schema, label, required);
	return (
		<GenericFormInputWrapper
			label={_label}
			required={_required}
			error={fieldState.error}
		>
			<StyledCheckbox {...field} />
		</GenericFormInputWrapper>
	);
};
export type LoadOptionsFn = (
	search: string,
	values?: string[],
) => Promise<GenericFormInputSelectOption[]>;
type GenericFormInputSelectProps<T extends FieldValues> =
	GenericFormInputProps<T> & {
		l: LoadOptionsFn;
		noClear?: boolean;
		disabled?: boolean;
	};
export const ISelect = <T extends FieldValues>({
	label,
	required,
	noClear,
	n,
	c,
	s,
	l,
	disabled,
}: GenericFormInputSelectProps<T>) => {
	const form = useFormContext<T>();
	const schema = useContext(SchemaContext);
	const { field, fieldState } = useController({
		name: n,
		control: c ?? form?.control,
	});
	const { _label, _required } = useSchemaInfo(n, s ?? schema, label, required);
	const handleChange = (option: SingleValue<GenericFormInputSelectOption>) =>
		field.onChange(option?.value ?? null);
	const handleLoadOptions = async (inputValue: string) => await l(inputValue);
	const defaultOptions = useAsync(
		() => l("", [field.value]),
		[l, field.value],
	).value;
	const value = useMemo(
		() =>
			field.value == null
				? null
				: defaultOptions?.find((option) => option.value === `${field.value}`),
		[field.value, defaultOptions],
	);
	return (
		<GenericFormInputWrapper
			label={_label}
			required={_required}
			error={fieldState.error}
		>
			<AsyncSelect<GenericFormInputSelectOption>
				{...field}
				onChange={handleChange}
				value={value}
				loadOptions={handleLoadOptions}
				defaultOptions={defaultOptions}
				cacheOptions
				isClearable={!noClear}
				isSearchable
				isDisabled={disabled}
			/>
		</GenericFormInputWrapper>
	);
};
export const IMultiSelect = <T extends FieldValues>({
	label,
	required,
	noClear,
	n,
	c,
	s,
	l,
	disabled,
}: GenericFormInputSelectProps<T>) => {
	const form = useFormContext<T>();
	const schema = useContext(SchemaContext);
	const { field, fieldState } = useController({
		name: n,
		control: c ?? form?.control,
	});
	const { _label, _required } = useSchemaInfo(n, s ?? schema, label, required);
	const handleChange = (option: MultiValue<GenericFormInputSelectOption>) =>
		option && field.onChange(option.map((x) => x.value));
	const handleLoadOptions = async (inputValue: string) => await l(inputValue);
	const defaultOptions = useAsync(
		() => l("", [field.value]),
		[l, field.value],
	).value;
	const value = useMemo(
		() =>
			field.value == null
				? null
				: defaultOptions?.filter((option) =>
						field.value.map((x: string) => `${x}`).includes(option.value),
					),
		[field.value, defaultOptions],
	);
	return (
		<GenericFormInputWrapper
			label={_label}
			required={_required}
			error={fieldState.error}
		>
			<AsyncSelect<GenericFormInputSelectOption, true>
				{...field}
				styles={{
					multiValueLabel: (base) => ({ ...base, maxWidth: 300 }),
				}}
				onChange={handleChange}
				value={value}
				loadOptions={handleLoadOptions}
				defaultOptions={defaultOptions}
				cacheOptions
				isMulti
				isClearable={!noClear}
				isSearchable
				isDisabled={disabled}
			/>
		</GenericFormInputWrapper>
	);
};
export const IMultiCreate = <T extends FieldValues>({
	label,
	required,
	n,
	c,
	s,
}: GenericFormInputProps<T>) => {
	const form = useFormContext<T>();
	const schema = useContext(SchemaContext);
	const { field, fieldState } = useController({
		name: n,
		control: c ?? form?.control,
	});
	const { _label, _required } = useSchemaInfo(n, s ?? schema, label, required);
	const handleChange = (option: MultiValue<GenericFormInputSelectOption>) =>
		field.onChange(option.map((x) => x.value));
	const value = useMemo(
		() =>
			field.value == null
				? null
				: field.value.map((x: string) => ({ label: x, value: x })),
		[field.value],
	);
	return (
		<GenericFormInputWrapper
			label={_label}
			required={_required}
			error={fieldState.error}
		>
			<ReactSelectCreatable<GenericFormInputSelectOption, true>
				{...field}
				styles={{
					multiValueLabel: (base) => ({ ...base, maxWidth: 300 }),
				}}
				onChange={handleChange}
				value={value}
				isMulti
				isClearable
				isSearchable
			/>
		</GenericFormInputWrapper>
	);
};

export const IDropzone = <T extends FieldValues>({
	label,
	required,
	n,
	c,
	s,
}: GenericFormInputProps<T>) => {
	const form = useFormContext<T>();
	const schema = useContext(SchemaContext);
	const { field, fieldState } = useController({
		name: n,
		control: c ?? form?.control,
	});
	const { _label, _required } = useSchemaInfo(n, s ?? schema, label, required);
	const [files, setFiles] = useState<File[]>([]);
	const onDrop = useCallback(
		(acceptedFiles: File[]) => {
			const newFiles = uniqBy([...files, ...acceptedFiles], (x) => x.name);
			setFiles(newFiles);
			field.onChange(newFiles);
		},
		[field, files],
	);
	const { getRootProps, getInputProps } = useDropzone({ onDrop });
	const removeFile = useCallback(
		(fileName: string) => {
			const newFiles = files.filter((file) => file.name !== fileName);
			setFiles(newFiles);
			field.onChange(newFiles);
		},
		[files, field],
	);
	return (
		<GenericFormInputWrapper
			label={_label}
			required={_required}
			error={fieldState.error}
		>
			<section className="container">
				<div {...getRootProps({ className: "dropzone" })}>
					<input {...getInputProps()} />
					<p>Drag 'n' drop some files here, or click to select files</p>
				</div>
				{files.length > 0 && (
					<aside>
						<h4>Files</h4>
						<ul>
							{files.map((file: File) => (
								<li key={file.name}>
									{file.name}{" "}
									<Button
										icon="close"
										size="small"
										onClick={() => removeFile(file.name)}
									/>
								</li>
							))}
						</ul>
					</aside>
				)}
			</section>
		</GenericFormInputWrapper>
	);
};

type InputMapProps<T extends FieldValues> = {
	label?: string;
	required?: boolean;
	c?: Control<T>;
	longitude: FieldPath<T>;
	latitude: FieldPath<T>;
	address: FieldPath<T>;
};
export const IMap = <T extends FieldValues>({
	label = "Map",
	required = false,
	longitude,
	latitude,
	address,
	c,
}: InputMapProps<T>) => {
	const form = useFormContext<T>();
	const lngCtrlr = useController({
		name: longitude,
		control: c ?? form?.control,
	});
	const latCtrlr = useController({
		name: latitude,
		control: c ?? form?.control,
	});
	const addrCtrlr = useController({
		name: address,
		control: c ?? form?.control,
	});
	const autocomplete = useRef<google.maps.places.Autocomplete>(null);
	const lat = (latCtrlr.field.value as number | undefined) ?? 55.94;
	const lng = (lngCtrlr.field.value as number | undefined) ?? -3.21;
	const error =
		lngCtrlr.fieldState.error ??
		latCtrlr.fieldState.error ??
		addrCtrlr.fieldState.error;
	const handlePlaceChanged = () =>
		handleChange(autocomplete.current?.getPlace());
	const handleChange = (
		result:
			| google.maps.GeocoderResult
			| google.maps.places.PlaceResult
			| undefined,
	) => {
		if (!result) return;
		const location = result.geometry?.location;
		if (!location) return;
		lngCtrlr.field.onChange(location.lng());
		latCtrlr.field.onChange(location.lat());
		const plusCode = result.address_components?.find((x) =>
			x.types.includes("plus_code"),
		)?.short_name;
		if (plusCode) {
			result.formatted_address = result.formatted_address
				?.replace(plusCode, "")
				.trim();
		}
		addrCtrlr.field.onChange(result.formatted_address);
	};
	return (
		<GenericFormInputWrapper label={label} required={required} error={error}>
			<GoogleMap
				mapContainerStyle={{ height: "400px" }}
				center={{ lat, lng }}
				zoom={12}
				options={{
					clickableIcons: false,
					disableDoubleClickZoom: true,
					disableDefaultUI: true,
				}}
				onClick={async ({ latLng }) => {
					if (!latLng) {
						return;
					}
					const result = await new google.maps.Geocoder()
						.geocode({ location: latLng })
						.then((x) => x.results[0]);
					handleChange(result);
				}}
			>
				<Autocomplete
					onPlaceChanged={handlePlaceChanged}
					onLoad={(x) => {
						autocomplete.current = x;
					}}
				>
					<input
						name="address"
						placeholder="Search..."
						style={{
							boxSizing: "border-box",
							border: "1px solid transparent",
							width: "240px",
							height: "32px",
							padding: "0 12px",
							borderRadius: "3px",
							boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
							fontSize: "14px",
							outline: "none",
							textOverflow: "ellipses",
							position: "relative",
							left: "50%",
							marginLeft: "-120px",
							top: "10px",
						}}
					/>
				</Autocomplete>
				<MarkerF position={{ lat, lng }} />
			</GoogleMap>
		</GenericFormInputWrapper>
	);
};

type GenericFormButtonsProps = {
	onReset: () => void;
	isSubmitting?: boolean;
	extraButtons?: ReactNode;
};
const GenericFormButtons = ({
	onReset,
	isSubmitting,
	extraButtons,
}: GenericFormButtonsProps) => (
	<p style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
		{extraButtons}
		<Button
			type="button"
			onClick={onReset}
			className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md"
		>
			Reset
		</Button>
		<Button type="submit" disabled={isSubmitting}>
			Submit
		</Button>
	</p>
);

type GenericFormProps<T extends FieldValues> = {
	onSubmit: (data: T) => Promise<unknown>;
	children: ReactNode;
	form: UseFormReturn<T>;
	schema: ObjectSchema<T>;
	extraButtons?: ReactNode;
};

const SchemaContext = createContext<AnyObjectSchema | undefined>(undefined);
export const GenericForm = <T extends FieldValues>({
	onSubmit,
	children,
	form,
	schema,
	extraButtons,
}: GenericFormProps<T>) => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	return (
		<form
			className="k-form"
			onSubmit={form.handleSubmit(async (data) => {
				setIsSubmitting(true);
				await onSubmit(data)
					.catch(() => setIsSubmitting(false))
					.finally(() => setIsSubmitting(false));
				setIsSubmitting(false);
			})}
		>
			<FormProvider {...form}>
				<SchemaContext.Provider value={schema}>
					{children}
				</SchemaContext.Provider>
			</FormProvider>
			<GenericFormButtons
				extraButtons={extraButtons}
				onReset={() => form.reset()}
				isSubmitting={isSubmitting}
			/>
		</form>
	);
};
